import { theme as defaultTheme, ThemeType } from '../theme'
import { useTheme } from '@material-ui/core'

type InlineRemFunction = (size: number) => (props: Record<string, unknown> & { theme: ThemeType }) => string

type ExpressiveRemFunction = (size: number, theme?: ThemeType) => string

export const irem: InlineRemFunction = (size) => ({ theme }) => rem(size, theme)

export const rem: ExpressiveRemFunction = (size, theme = defaultTheme) => `${size / theme.typography.fontSize}rem`

export const useRem = () => {
  const theme = useTheme() as ThemeType

  return (size: number) => rem(size, theme)
}